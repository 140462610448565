import React, {Suspense, useEffect, useState} from "react";
import {Grid, Skeleton, Stack, Typography,} from "@mui/material";
import {ShepherdTour} from "react-shepherd";

import Map from "../../components/map/Map";
import {MapProvider} from "/@/context/mapContext";
import {useTranslation} from "react-i18next";
import GraphSettings from "../../components/map/GraphSettings";
import {tourOptions} from "/@/components/shepherdTour/shepardTourOptions";
import AirQualityGraphs from "/@/components/map/AirQualityGraphs";
import SwipeUpIcon from "@mui/icons-material/SwipeUp";
import {useThemeContext} from "/@/context/themeContext";
import MobileDrawer from "/@/components/map/MobileDrawer";
import {useMapData} from "/@/context/useMapData";
import {
    LeftSide,
    MapArea,
    PageGridWrapper,
    PullUpTab,
    SidebarGridWrapper
} from "/@/components/map/MapPageStyledComponents";

type Props = {};

export interface LocationCoords {
    lat: number | undefined;
    lng: number | undefined;
}


function Page(props: Props) {
    const {isMobile} = useThemeContext()
    const {t, i18n} = useTranslation();
    const {sensorData, fetchAirData} = useMapData();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = (open: boolean) => {
        setDrawerOpen(open);
    };

    useEffect(() => {
        fetchAirData(false);
    }, []);

    useEffect(() => {
        // Check if the app is running on a mobile device
        const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);

        if (isMobileDevice) {
            // Delay the scroll to give the page time to load
            setTimeout(() => {
                // Scroll 1 pixel to trigger the address bar to hide
                window.scrollTo(0, 1);
            }, 1000); // Adjust the delay as needed
        }
    }, []);

    return (
        <Suspense fallback={<Skeleton variant="rectangular" width="100%" height="100%"/>}>
            <MapProvider>
                <ShepherdTour steps={[]} tourOptions={tourOptions}>
                    <PageGridWrapper container>
                        <LeftSide item xs={12} md={7}>
                            <MapArea>
                                <Map
                                    toggleDrawer={toggleDrawer}
                                    sensorData={sensorData}
                                    isMapPage={true}
                                    setSelectedSensor={() => {
                                    }}
                                    selectedSensorId={""}
                                />
                            </MapArea>

                            <PullUpTab onClick={() => toggleDrawer(true)}>
                                <SwipeUpIcon/>
                                <Typography variant="body1">{t('swiper_button')}</Typography>
                            </PullUpTab>
                            {isMobile && (
                                <MobileDrawer open={drawerOpen} toggleDrawer={toggleDrawer}/>
                            )}
                        </LeftSide>
                        <Grid item md={5} padding={2}>
                            <SidebarGridWrapper>
                                <Stack sx={{padding: '10px'}}>
                                    <GraphSettings toggleDrawer={toggleDrawer}/>
                                    <AirQualityGraphs/>
                                </Stack>
                            </SidebarGridWrapper>
                        </Grid>
                    </PageGridWrapper>
                </ShepherdTour>
            </MapProvider>
        </Suspense>
    );
}

export default Page;
